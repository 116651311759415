<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="rubric.id" @deleteRecord="deleteRubric"></app-button-delete>
              <app-button-close route-name="rubric_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-6">
                    <app-select
                      v-model="rubric.product"
                      :options="edonProducts"
                      optionTitle="title"
                      :convertValue="false"
                      :error="$v.rubric.product.$error"
                      id="rubric_product"
                      :label="productLabel"
                    >
                    </app-select>
                    <input type="hidden" v-model="rubric.id">
                    <app-select
                      v-model="rubric.site"
                      :options="sites"
                      id="rubric_site"
                      :error="$v.rubric.site.$error"
                      :label="`${$t('rubric.site')}*`"
                    >
                    </app-select>

                    <app-input
                      v-model="rubric.title"
                      @blur="$v.rubric.title.$touch()"
                      :error="$v.rubric.title.$error"
                      id="rubric_title"
                      :label="`${$t('rubric.title')}*`"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.slug"
                      @blur="$v.rubric.slug.$touch()"
                      :error="$v.rubric.slug.$error"
                      id="rubric_slug"
                      :label="`${$t('rubric.slug')}*`"
                    >
                    </app-input>

                    <div class="form-group">
                      <label>{{ $t('rubric.parent') }}</label>
                      <select v-model="rubric.parent" id="rubric_parent" class="form-control">
                        <option
                          v-for="(rubric, index) in rubricsBySite"
                          :key="`item-${index}`"
                          :value="rubric.id"
                        >
                          {{ '&nbsp;'.repeat(rubric.lvl * 4) }}
                          {{ rubric.title }}
                        </option>
                      </select>
                    </div>

                    <app-input
                      v-model="rubric.sorting"
                      @blur="$v.rubric.sorting.$touch()"
                      :error="$v.rubric.sorting.$error"
                      id="rubric_meta_sorting"
                      type="number"
                      :min="0"
                      :label="$t('rubric.sorting')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.title"
                      @blur="$v.rubric.meta.title.$touch()"
                      :error="$v.rubric.meta.title.$error"
                      id="rubric_meta_title"
                      :label="$t('rubric.meta_title')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.description"
                      @blur="$v.rubric.meta.description.$touch()"
                      :error="$v.rubric.meta.description.$error"
                      id="rubric_meta_description"
                      :label="$t('rubric.meta_description')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.meta.keywords"
                      @blur="$v.rubric.meta.keywords.$touch()"
                      :error="$v.rubric.meta.keywords.$error"
                      id="rubric_meta_keywords"
                      :label="$t('rubric.meta_keywords')"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-6">
                    <app-input
                      v-model="rubric.setting.advertCategory"
                      @blur="$v.rubric.setting.advertCategory.$touch()"
                      :error="$v.rubric.setting.advertCategory.$error"
                      id="rubric_setting_advertCategory"
                      :label="$t('rubric.setting_advertCategory')"
                    >
                    </app-input>
                    <app-input
                      v-model="rubric.setting.advertSection"
                      @blur="$v.rubric.setting.advertSection.$touch()"
                      :error="$v.rubric.setting.advertSection.$error"
                      id="rubric_setting_advertSection"
                      :label="$t('rubric.setting_advertSection')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.setting.chartbeatSection"
                      @blur="$v.rubric.setting.chartbeatSection.$touch()"
                      :error="$v.rubric.setting.chartbeatSection.$error"
                      id="rubric_setting_chartbeatSection"
                      :label="$t('rubric.setting_chartbeatSection')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.setting.gemiusId"
                      @blur="$v.rubric.setting.gemiusId.$touch()"
                      :error="$v.rubric.setting.gemiusId.$error"
                      id="rubric_setting_gemiusId"
                      :label="$t('rubric.setting_gemiusId')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.sportTable.seasonId"
                      @blur="$v.rubric.sportTable.seasonId.$touch()"
                      :error="$v.rubric.sportTable.seasonId.$error"
                      id="rubric_sportTable_seasonId"
                      type="number"
                      :min="0"
                      :label="$t('rubric.sportTable_seasonId')"
                    >
                    </app-input>

                    <app-input
                      v-model="rubric.sportTable.activeTab"
                      @blur="$v.rubric.sportTable.activeTab.$touch()"
                      :error="$v.rubric.sportTable.activeTab.$error"
                      id="rubric_sportTable_activeTab"
                      type="number"
                      :min="1"
                      :label="$t('rubric.sportTable_activeTab')"
                    >
                    </app-input>

                    <div v-if="showIptcCategory" class="form-group">
                      <label>
                        {{ $t('rubric.iptcCategory') }}
                      </label>
                      <MultiSelect
                        :value="rubric.iptcCategories ? rubric.iptcCategories.map(categoryId => (iptcCategories.find(c => c.id === categoryId))): []"
                        @input="(value) => rubric.iptcCategories = value ? value.map(v => v.id) : []"
                        :options="iptcCategories"
                        label="name"
                        track-by="id"
                        id="rubric_iptcCategory"
                      />
                    </div>

                    <app-checkbox
                      v-model="rubric.setting.prRubric"
                      id="rubric_setting_prRubric"
                      :label="$t('rubric.setting_prRubric')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.setting.special"
                      id="rubric_setting_special"
                      :label="$t('rubric.setting_special')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.rubricWithoutArticles"
                      id="rubric_rubricWithoutArticles"
                      :label="$t('rubric.rubric_without_articles')"
                    >
                    </app-checkbox>

                    <app-checkbox
                      v-model="rubric.hiddenInMenu"
                      id="rubric_hiddenInMenu"
                      :label="$t('rubric.hiddenInMenu')"
                    >
                    </app-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import store from '@/store'
import ModuleView from '../../components/ModuleView'
import Input from '../../components/form/inputs/Input'
import Select from '../../components/form/select/Select'
import { integer, maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import RubricModel from '../../model/Rubric'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import MultiSelect from '@/components/form/select/MultiSelect'
import NotifyService from '../../services/NotifyService'
import CoreApi from '@/api/core'
import Checkbox from '../../components/form/Checkbox'
const IS_VLM = store.getters.vlm

export default {
  name: 'RubricNewView',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      iptcCategories: [],
      rubric: this._.cloneDeep(RubricModel),
      rubricsBySite: []
    }
  },
  computed: {
    sites () {
      return this.$store.getters['site/enabledSites']()
    },
    rubrics () {
      return this.$store.getters['rubric/list']
    },
    showIptcCategory () {
      return IS_VLM
    },
    edonProducts () {
      return this.$store.getters['edonProduct/list']
    },
    productLabel () {
      const label = this.$t('rubric.product')
      return IS_VLM ? label : `${label}*`
    }
  },
  validations: {
    rubric: {
      product: IS_VLM ? {} : {
        required
      },
      site: {
        required
      },
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      slug: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(255)
      },
      meta: {
        title: {
          maxLength: maxLength(255)
        },
        description: {
          maxLength: maxLength(255)
        },
        keywords: {
          maxLength: maxLength(255)
        }
      },
      setting: {
        advertCategory: {
          maxLength: maxLength(255)
        },
        advertSection: {
          maxLength: maxLength(255)
        },
        chartbeatSection: {
          maxLength: maxLength(255)
        },
        gemiusId: {
          maxLength: maxLength(80)
        }
      },
      sorting: {
        integer,
        minValue: minValue(0)
      },
      sportTable: {
        seasonId: {
          integer,
          minValue: minValue(0)
        },
        activeTab: {
          integer,
          minValue: minValue(1)
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appSelect: Select,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox,
    MultiSelect
  },
  methods: {
    getEdonProducts () {
      this.$store.dispatch('edonProduct/fetchAll')
    },
    async loadRubricsBySite () {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', this.rubric.site)
      this.rubricsBySite = this.$store.state.rubric.all.filter(item => item.site === this.rubric.site)
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        this.$store.dispatch('rubric/create', this.rubric)
          .then(() => {
            if (this.$store.getters['rubric/error'] === null) {
              NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
              this.rubric = this.$store.getters['rubric/detail']
              this.$router.push('/rubric/' + this.rubric.id + '/edit')
            } else {
              NotifyService.setErrorMessage(this.$store.getters['rubric/error'])
            }
          })
          .catch(error => console.error(error))
      }
    },
    deleteRubric () {
      this.$store.dispatch('rubric/deleteRecord', this.rubric)
        .then(() => {
          if (this.$store.getters['rubric/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/rubric')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['rubric/error'])
          }
        })
        .catch(error => console.error(error))
    },
    getSites () {
      this.$store.dispatch('site/fetchAll')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getCategories () {
      CoreApi().get('/geneeaCategory')
        .then(response => { this.iptcCategories = response.data.data })
        .catch(error => console.error(error))
    }
  },
  mounted () {
    this.getSites()
    this.getEdonProducts()
    if (IS_VLM) {
      this.getCategories()
    }
  },
  watch: {
    rubric: {
      deep: true,
      handler: function () {
        this.loadRubricsBySite()
      }
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
